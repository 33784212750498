import { mapState, mapGetters, mapActions } from 'vuex'
import { Auth } from '@/plugins/firebase'
import Metrics from '@/plugins/metrics'
import { recaptcha } from '@/mixins/recaptcha'

import SButton from '@/components/ui/s-button'
import SLink from '@/components/ui/s-link'
import STitle from '@/components/ui/s-title'

export default {
  name: 'RightGroupActions',
  mixins: [recaptcha],

  components: {
    SButton,
    SLink,
    STitle,
    SingleSignOnButton: () => import('@/components/SSO/SingleSignOnButton'),
  },

  mounted() {
    const {
      rc: code /* rc = report code encoded */,
      ch = 'sms' /* ch = channel */,
    } = this.$route.query

    if (code) {
      this.protocol = atob(code)
      Metrics.track('Follow Up by Notification Channel', { channel: ch })
    }
  },

  data() {
    const { source } = this.$router.currentRoute.params

    return {
      source,
      loading: false,
      protocol: null,
      showError: false,
    }
  },

  computed: {
    ...mapState(['error', 'success', 'config']),
    ...mapGetters(['isSourceCollaborator', 'isFeatureEnabled']),

    disabledButton() {
      return !this.protocol || this.loading
    },

    isAnonymousUser() {
      return Auth.currentUser?.isAnonymous
    },

    isCollaborator() {
      return this.isSourceCollaborator(this.source)
    },

    providers() {
      return this.config.sso?.providers || []
    },

    isSSOEnabled() {
      return this.isFeatureEnabled('singleSignOn')
    },
  },

  methods: {
    ...mapActions(['getReportByCode', 'signInAnonymous']),

    async getProtocol() {
      this.loading = true

      const success = await this.performRecaptcha('getResourceByCode')
        .catch((error) => {
          this.setError(error)
          this.showError = true
        })
        .finally(() => {
          this.loading = false
        })

      if (success) {
        if (!Auth.currentUser?.uid) {
          return this.signInAnonymous()
        }

        const code = this.protocol?.trim()

        if (code) {
          const { id: reportId } = await this.getReportByCode({ code })

          if (reportId) {
            this.$router.push({
              name: 'report-follow-up',
              params: { token: Date.now(), reportId, code },
            })
          }
        } else {
          this.showError = true
          this.setError('UNAVAILABLE')
        }
      }
    },
  },

  watch: {
    error() {
      this.showError = !!this.error
    },
  },
}
